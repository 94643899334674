@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.surveyIntro {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.surveyIntro .surveyIntroImageContainer {
  width: 100%;
  height: 325px;
  position: relative;
  overflow: hidden;
}
.surveyIntro .surveyIntroImageContainer .brandLogo {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 2;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel {
  height: 100%;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel #imageCarouselHeader {
  display: none;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel ul {
  height: 100%;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel ul li.slide {
  position: absolute;
  height: 100%;
  width: 100%;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel ul li.slide .carouselImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  -webkit-animation: fade 0.5s;
          animation: fade 0.5s;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel ul li.slide.active {
  z-index: 1;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel .animationButton {
  position: absolute;
  bottom: 0;
  right: 20px;
  padding: 15px;
  border-radius: 50%;
  background-color: #002e6d;
  color: white;
  z-index: 3;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel .animationButton:before {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel .animationButton #animationButtonText {
  display: none;
}
.surveyIntro .surveyIntroImageContainer:after {
  content: "";
  position: absolute;
  height: 880px;
  width: 880px;
  bottom: -820px;
  left: calc(-440px + 50%);
  border-radius: 50%;
  background-color: #002e6d;
  z-index: 2;
}
.surveyIntro .surveyIntroImageContainer .accentImageMobile {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 60px);
  width: 120px;
  z-index: 3;
}
.surveyIntro .surveyIntroContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  background-color: #002e6d;
  color: white;
  padding: 10px 40px 40px;
}
.surveyIntro .surveyIntroContent .surveyIntroHeader {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 3.375rem;
  line-height: 3.625rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  margin-bottom: 15px;
}
.surveyIntro .surveyIntroContent .surveyIntroDescription {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  margin-bottom: 60px;
}
.surveyIntro .surveyIntroContent button.surveyGetStarted {
  background-color: white;
  color: #002e6d;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  display: -ms-flexbox;
  display: flex;
}
.surveyIntro .surveyIntroContent button.surveyGetStarted:focus {
  outline-color: white;
}
.surveyIntro .surveyIntroContent button.surveyGetStarted:after {
  font-family: 'Icon Fonts';
  content: "\e92a";
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-left: 10px;
}
.surveyIntro .surveyIntroContent .logInContainer {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  margin-top: 25px;
}
.surveyIntro .surveyIntroContent .logInContainer .logInLink {
  color: white;
  text-decoration: underline;
}
.surveyIntro .surveyIntroContent .logInContainer .logInLink:hover,
.surveyIntro .surveyIntroContent .logInContainer .logInLink:focus {
  text-decoration: none;
}
.surveyIntro .surveyIntroContent .logInContainer .logInLink:focus {
  outline-color: white;
}
.surveyIntro .surveyIntroContent .accentImageDesktop {
  display: none;
}
