.thankYouPage .thankYouContainer {
  text-align: center;
}
.thankYouPage .thankYouContainer .thankYouHeaderText {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 10px;
}
.thankYouPage .thankYouContainer .thankYouHeaderText a {
  display: inline-block;
}
.thankYouPage .thankYouContainer .thankYouHeaderText a {
  color: #3273f8;
  text-decoration: underline;
}
.thankYouPage .thankYouContainer .thankYouHeaderText a:hover,
.thankYouPage .thankYouContainer .thankYouHeaderText a:focus {
  text-decoration: none;
}
.thankYouPage .thankYouContainer .thankYouDescription {
  text-align: center;
  margin-bottom: 35px;
}
.thankYouPage .thankYouContainer .thankYouDescription img {
  max-height: 200px;
  max-width: 100%;
}
.thankYouPage .thankYouContainer .thankYouDescription h3 {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 10px;
}
.thankYouPage .thankYouContainer .thankYouDescription p {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.25rem;
}
.thankYouPage .thankYouContainer .thankYouButtons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}
.thankYouPage .thankYouContainer .thankYouButtons .ctaButton {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 20px;
  padding: 10px 30px;
}
.thankYouPage .thankYouContainer .thankYouButtons .ctaButton:hover {
  text-decoration: none;
}
.thankYouPage .thankYouContainer .thankYouButtons .ctaButton a {
  color: #fff;
}
.thankYouPage .thankYouContainer .thankYouButtons .ctaButton:focus {
  border-radius: 100px;
}
.thankYouPage .thankYouContainer .thankYouButtons .backButton {
  color: #002e6d;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-transform: none;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
}
.thankYouPage .thankYouContainer .thankYouButtons .backButton:hover:enabled {
  text-decoration: underline;
}
