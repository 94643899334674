.registrationPage.profilePage .twoColumnProfileSection .valuePropColumn {
  display: block;
}
.registrationPage .registrationForm {
  padding: 0 40px;
}
.registrationPage .requiredText {
  width: 100%;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  margin: 10px 0;
}
.registrationPage .registrationLabel {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.registrationPage .registrationHelpText {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: block;
  margin-bottom: 5px;
}
.registrationPage .inputSkeleton {
  background-color: #f5f5f6;
}
.registrationPage .inputSkeleton.passwordSkeleton {
  margin-bottom: 150px;
}
.registrationPage .registrationSocial {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 30px;
  font-weight: bold;
}
.registrationPage .registrationSocial img.providerImage {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}
.registrationPage .hhCompSkeleton {
  display: block;
  background-color: #f5f5f6;
  width: 100%;
  height: 90px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.registrationPage .registrationConsentLanguage {
  display: -ms-flexbox;
  display: flex;
}
.registrationPage .registrationConsentLanguage .registrationLegalText {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 20px;
  line-height: 1.25rem;
}
.registrationPage .registrationConsentLanguage .registrationLegalText a {
  font-weight: bold;
  text-decoration: underline;
}
.registrationPage .registrationConsentLanguage .registrationLegalText a:hover,
.registrationPage .registrationConsentLanguage .registrationLegalText a:focus {
  text-decoration: none;
}
.registrationPage .registrationNewsletterSection {
  background-color: #f5f5f6;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
}
.registrationPage .registrationNewsletterSection .newsletterHeading {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  margin-bottom: 10px;
}
.registrationPage .registrationNewsletterSection .newsletterHeading a {
  display: inline-block;
}
.registrationPage .registrationNewsletterSection .newsletterHeading a {
  color: #3273f8;
  text-decoration: underline;
}
.registrationPage .registrationNewsletterSection .newsletterHeading a:hover,
.registrationPage .registrationNewsletterSection .newsletterHeading a:focus {
  text-decoration: none;
}
.registrationPage .registrationNewsletterSection .newsletterHeading a {
  color: #002e6d;
}
.registrationPage .registrationNewsletterSection .newsletterLabel {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.registrationPage .registrationNewsletterSection .newsletterPreview {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  margin-top: 5px;
}
.registrationPage .registrationNewsletterSection .newsletterLabel,
.registrationPage .registrationNewsletterSection .newsletterPreview {
  display: -ms-flexbox;
  display: flex;
}
.registrationPage .registrationNewsletterSection .newsletterLabel .newsletterCheckboxSpacing,
.registrationPage .registrationNewsletterSection .newsletterPreview .newsletterCheckboxSpacing {
  width: 25px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.registrationPage .registrationSubmitSection {
  text-align: center;
}
.registrationPage .recaptcha > div {
  margin: 20px auto 0;
}
.registrationPage .errorSection {
  margin-top: 20px;
  background-color: #FCE5E5;
  padding: 20px;
  border-radius: 6px;
}
.registrationPage .errorSection.socialErrorSection {
  margin-bottom: 20px;
}
.registrationPage .errorSection .errorHeading {
  font-weight: bold;
}
.registrationPage .errorSection .errorListItem {
  margin-top: 5px;
}
.registrationPage .inputErrors {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: #db0038;
  margin-bottom: 10px;
  margin-top: 3px;
}
input + .registrationPage .inputErrors {
  margin-top: -15px;
}
.registrationPage .registrationBlockedHeading {
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #3273f8;
}
.registrationPage .registrationBlockedHeading a {
  display: inline-block;
}
.registrationPage .registrationBlockedHeading a {
  color: #3273f8;
  text-decoration: underline;
}
.registrationPage .registrationBlockedHeading a:hover,
.registrationPage .registrationBlockedHeading a:focus {
  text-decoration: none;
}
.registrationPage .fieldInputParent {
  display: -ms-flexbox;
  display: flex;
}
