.profilePage .hiddenPageTitle {
  visibility: hidden;
  height: 0;
}
.profilePage .twoColumnProfileSection {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.profilePage .twoColumnProfileSection .valuePropColumn {
  display: none;
  background-color: #002e6d;
  color: white;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a {
  display: inline-block;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a {
  color: #3273f8;
  text-decoration: underline;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a:hover,
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a:focus {
  text-decoration: none;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropContentDesktop {
  display: none;
}
.profilePage .twoColumnProfileSection .valuePropColumn ul li {
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 10px;
}
.profilePage .twoColumnProfileSection .contentColumn {
  padding: 10px 0px;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeading,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeading,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeading,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordHeading,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountHeading {
  margin-bottom: 20px;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeading,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeading {
  padding: 0 10px;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeading,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeading,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeaderText,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeaderText,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeaderText,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordHeaderText,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountHeaderText {
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #3273f8;
  margin-bottom: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountHeaderText a {
  display: inline-block;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordHeaderText a,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountHeaderText a {
  color: #3273f8;
  text-decoration: underline;
}
.profilePage .twoColumnProfileSection .contentColumn .loginHeaderText a:hover,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeaderText a:hover,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeaderText a:hover,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordHeaderText a:hover,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountHeaderText a:hover,
.profilePage .twoColumnProfileSection .contentColumn .loginHeaderText a:focus,
.profilePage .twoColumnProfileSection .contentColumn .registrationHeaderText a:focus,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetHeaderText a:focus,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordHeaderText a:focus,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountHeaderText a:focus {
  text-decoration: none;
}
.profilePage .twoColumnProfileSection .contentColumn .signUpContainer,
.profilePage .twoColumnProfileSection .contentColumn .logInContainer {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: end;
      align-items: flex-end;
}
.profilePage .twoColumnProfileSection .contentColumn .signUpContainer a,
.profilePage .twoColumnProfileSection .contentColumn .logInContainer a {
  font-weight: bold;
}
.profilePage .twoColumnProfileSection .contentColumn .socialLogin {
  width: 100%;
  margin-top: 10px;
}
.profilePage .twoColumnProfileSection .contentColumn .socialLogin ul.socialConnectItems {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  gap: 10px;
}
.profilePage .twoColumnProfileSection .contentColumn .socialLogin ul.socialConnectItems li.socialConnectItem {
  -ms-flex: 1;
      flex: 1;
}
.profilePage .twoColumnProfileSection .contentColumn .socialLogin ul.socialConnectItems li.socialConnectItem .socialConnectButton {
  width: 100%;
}
.profilePage .twoColumnProfileSection .contentColumn .loginForm,
.profilePage .twoColumnProfileSection .contentColumn .registrationForm,
.profilePage .twoColumnProfileSection .contentColumn .requestPasswordResetForm,
.profilePage .twoColumnProfileSection .contentColumn .resetPasswordForm,
.profilePage .twoColumnProfileSection .contentColumn .linkSocialAccountForm,
.profilePage .twoColumnProfileSection .contentColumn .successCopySection,
.profilePage .twoColumnProfileSection .contentColumn .invalidTokenSection {
  border-top: 2px dotted #002e6d;
  padding-top: 20px;
}
.profilePage input,
.profilePage select {
  border-radius: 6px;
  padding: 9px;
  border: solid 1px #888;
}
