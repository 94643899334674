.progressBar ol li .questionStepButton {
  font-weight: normal;
  color: #002e6d;
}
.progressBar ol li .questionStepButton:before {
  color: #002e6d;
  border: 2px solid #002e6d;
}
.progressBar ol li.current .questionStepButton {
  color: #3273f8;
  font-weight: bold;
}
.progressBar ol li.current .questionStepButton:before {
  background: #3273f8;
  border: 2px solid #3273f8;
  color: white;
}
