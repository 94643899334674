@media screen and (min-width:767px) {
.profilePage .twoColumnProfileSection {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: stretch;
      -ms-grid-row-align: stretch;
      align-items: stretch;
}
.profilePage .twoColumnProfileSection .valuePropColumn {
  width: 350px;
  text-align: left;
  margin-bottom: 0;
  padding: 30px;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: white;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a {
  display: inline-block;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a {
  color: #3273f8;
  text-decoration: underline;
}
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a:hover,
.profilePage .twoColumnProfileSection .valuePropColumn #valuePropHeading a:focus {
  text-decoration: none;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropContentDesktop {
  display: block;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropContentMobile {
  display: none;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropSubheading {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropSubheading a {
  display: inline-block;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropSubheading a {
  color: #3273f8;
  text-decoration: underline;
}
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropSubheading a:hover,
.profilePage .twoColumnProfileSection .valuePropColumn .valuePropSubheading a:focus {
  text-decoration: none;
}
.profilePage .twoColumnProfileSection .contentColumn {
  padding-left: 30px;
  -ms-flex-positive: 1;
      flex-grow: 1;
}

}
