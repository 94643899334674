.loginPage .loginForm {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.loginPage .loginForm .loginFormContainer {
  width: 100%;
}
.loginPage .loginForm .loginFormContainer .errorSection {
  width: 100%;
  border-radius: 6px;
  background-color: #FCE5E5;
  margin-bottom: 20px;
}
.loginPage .loginForm .loginFormContainer .errorSection .errorHeading {
  padding: 20px;
}
.loginPage .loginForm .loginFormContainer .loginLabel {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.loginPage .loginForm .loginFormContainer .passwordSkeleton {
  margin-bottom: 50px;
}
.loginPage .loginForm .loginFormContainer .loginRememberMeSection {
  margin-top: -30px;
  width: 50%;
}
.loginPage .loginForm .loginFormContainer .loginAuthenticateSection {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.loginPage .loginForm .loginFormContainer .recaptcha > div {
  margin: 20px auto 0;
}
.loginPage .forgotPasswordContainer {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.loginPage .forgotPasswordContainer .forgotPasswordText {
  padding: 20px;
  text-align: center;
  border-radius: 6px;
  background-color: #f5f5f6;
}
.loginPage .forgotPasswordContainer .forgotPasswordText a {
  font-weight: bold;
}
