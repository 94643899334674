.resetPasswordPage .resetPasswordForm {
  padding: 0 40px;
}
.resetPasswordPage .resetPasswordForm .resetPasswordLabel {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.resetPasswordPage .resetPasswordForm .errorSection {
  margin-top: 20px;
  background-color: #FCE5E5;
  padding: 20px;
  border-radius: 6px;
}
.resetPasswordPage .resetPasswordForm .errorSection .errorHeading {
  font-weight: bold;
}
.resetPasswordPage .resetPasswordForm .errorSection .errorListItem {
  margin-top: 5px;
}
.resetPasswordPage .successCopySection,
.resetPasswordPage .invalidTokenSection {
  text-align: center;
}
.resetPasswordPage .successCopySection .successMessage,
.resetPasswordPage .invalidTokenSection .successMessage,
.resetPasswordPage .successCopySection .invalidTokenMessage,
.resetPasswordPage .invalidTokenSection .invalidTokenMessage {
  margin-bottom: 20px;
}
