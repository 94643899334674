.zipCodeInput {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
}
.zipCodeInput .zipCodeField {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.zipCodeInput .zipCodeField .zipCodeFieldInput:focus {
  outline: 2px solid #002e6d;
  border: 1px solid transparent;
}
.zipCodeInput .zipCodeField .zipCodeFieldInput:active {
  outline: none;
  border: 1px solid #002e6d;
  box-shadow: 0 0 6px 0 rgba(0, 0, 180, 0.25);
  padding: 9px;
}
