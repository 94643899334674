.passwordInput {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
}
.passwordInput .passwordField {
  margin-bottom: 10px;
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.passwordInput .passwordField .passwordFieldInput {
  margin-bottom: 5px;
}
.passwordInput .passwordField .passwordFieldInput:focus {
  outline: 2px solid #002e6d;
  border: 1px solid transparent;
}
.passwordInput .passwordField .passwordFieldInput:active {
  outline: none;
  border: 1px solid #002e6d;
  box-shadow: 0 0 6px 0 rgba(0, 0, 180, 0.25);
  padding: 9px;
}
.passwordInput .passwordField .passwordButtonContainer {
  text-align: right;
}
.passwordInput .passwordField .passwordButtonContainer .showPasswordButton {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #002e6d;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-weight: bold;
}
.passwordInput .passwordField .passwordButtonContainer .showPasswordButton:hover:enabled {
  text-decoration: underline;
}
.passwordInput .passwordField .passwordButtonContainer .showPasswordButton:focus {
  text-decoration: underline;
}
.passwordInput #passwordCriteriaBox {
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #333;
  background-color: #f5f5f6;
  border-radius: 6px;
  padding: 10px 20px;
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 20px;
}
.passwordInput #passwordCriteriaBox .criteriaHeading {
  font-weight: bold;
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem {
  margin-top: 5px;
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem.validInput {
  color: #2F8118;
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem.validInput .criteriaIcon:before {
  content: "\e932";
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem.invalidInput {
  color: #E60000;
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem.invalidInput .criteriaIcon:before {
  content: "\e935";
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem .criteriaIcon {
  width: 10px;
  padding: 0 5px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  font-family: 'Icon Fonts';
}
.passwordInput #passwordCriteriaBox .criteriaItems .criteriaItem .criteriaIcon:before {
  content: "\25CF";
}
