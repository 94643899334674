.survey .surveyContainer .currentStepContent h2 {
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceText {
  border: #002e6d 2px solid;
  color: #002e6d;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput:checked + .choiceText {
  color: #fff;
}
.survey .surveyContainer .surveyRegistrationText h2 {
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
