.surveyIntro .surveyIntroImageContainer .brandLogo {
  height: 80px;
  width: auto;
  left: 10px;
}
.surveyIntro .surveyIntroContent .surveyIntroHeader {
  font-style: italic;
}
.surveyIntro .surveyIntroContent .surveyIntroDescription {
  font-weight: 500;
}
