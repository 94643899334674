@media screen and (min-width:1000px) {
.surveyIntro {
  -ms-flex-direction: row;
      flex-direction: row;
}
.surveyIntro .surveyIntroImageContainer {
  width: 66%;
  height: unset;
}
.surveyIntro .surveyIntroImageContainer .brandLogo {
  display: none;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel .animationButton {
  right: unset;
  padding: 20px 20px 10px 10px;
  background-color: transparent;
  border-radius: 3px;
}
.surveyIntro .surveyIntroImageContainer .imageCarousel .animationButton:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 58px;
  height: 62px;
  border-radius: 50%;
  background-color: #002e6d;
  z-index: -1;
}
.surveyIntro .surveyIntroImageContainer:after {
  top: calc(-750px + 50%);
  right: -1425px;
  bottom: unset;
  left: unset;
}
.surveyIntro .surveyIntroImageContainer .accentImageMobile {
  display: none;
}
.surveyIntro .surveyIntroContent {
  width: 34%;
  padding: 150px 60px 150px 20px;
  position: relative;
  overflow: hidden;
}
.surveyIntro .surveyIntroContent .accentImageDesktop {
  display: initial;
  width: 120px;
  position: absolute;
  bottom: -10px;
}

}
