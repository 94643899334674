.birthdateInput .birthdateInputContainer {
  display: -ms-flexbox;
  display: flex;
}
.birthdateInput .birthdateInputContainer .dateInputBox {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 6px;
  border-color: #888;
  padding: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.error .birthdateInput .birthdateInputContainer .dateInputBox,
.birthdateInput .birthdateInputContainer .dateInputBox.error,
.birthdateInput .birthdateInputContainer .dateInputBox.alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error .birthdateInput .birthdateInputContainer .dateInputBox:focus,
.birthdateInput .birthdateInputContainer .dateInputBox.error:focus,
.birthdateInput .birthdateInputContainer .dateInputBox.alert-error:focus {
  outline-width: 0;
}
.birthdateInput .birthdateInputContainer .dateInputBox .dateInputField {
  border: none;
  width: 3.2em;
  display: inline;
  margin-bottom: 0;
  text-align: center;
  outline: none;
  background-color: transparent;
  -moz-appearance: textfield;
  /* hide number spinner */
}
.birthdateInput .birthdateInputContainer .dateInputBox .dateInputField:focus {
  outline: none;
}
.birthdateInput .birthdateInputContainer .dateInputBox .dateInputField::-webkit-inner-spin-button {
  display: none;
  /* hide number spinner */
}
.birthdateInput .birthdateInputContainer .dateInputBox .dateInputField.dateInputYear {
  width: 5em;
}
.birthdateInput .birthdateInputContainer .dateInputBox .dateSeparator {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: #888;
}
.birthdateInput .birthdateInputContainer .dateInputBox.focused {
  outline: 2px solid #002e6d;
  border: 1px solid transparent;
}
.birthdateInput .birthdateInputContainer .dateInputBox:active {
  outline: none;
  border: 1px solid #002e6d;
  box-shadow: 0 0 6px 0 rgba(0, 0, 180, 0.25);
  padding: 1px;
}
