.survey.hideHeaderFooter .surveyContainer .modal .modalHeader {
  padding: 0;
  min-height: 0;
}
.survey.hideHeaderFooter .surveyContainer .modal .modalHeader .modalHeaderText {
  display: none;
}
.survey.hideHeaderFooter .surveyContainer .modal .modalHeader .modalClose {
  z-index: 3;
}
.survey.hideHeaderFooter .surveyContainer .modal .modalHeader .modalClose .modalCloseButton:after {
  color: #002e6d;
  background-color: white;
  border-radius: 50%;
}
.survey.hideHeaderFooter .surveyContainer .modal .modalContent {
  padding: 0;
}
.survey.hideHeaderFooter .surveyContainer .modal .modalFooter {
  display: none;
}
.survey .surveyContainer .modal {
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
}
.survey .surveyContainer .modal .modalHeader {
  padding: 0;
  min-height: auto;
}
.survey .surveyContainer .modal .modalHeader .surveyTitle {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.5rem;
  line-height: 1.875rem;
}
.survey .surveyContainer .modal .modalFooter:empty {
  display: none;
}
.survey .surveyContainer .currentStepContent {
  text-align: center;
}
.survey .surveyContainer .currentStepContent p {
  margin-top: 10px;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput {
  text-align: center;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput legend {
  margin: auto;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .questionDescription,
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .questionValidation {
  color: #212121;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .questionValidation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-weight: 600;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .questionValidation.valid {
  color: #2F8118;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .questionValidation.valid:before {
  margin-right: 10px;
  font-weight: normal;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: 'Icon Fonts';
  content: "\e933";
  color: #2F8118;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .questionValidationHiddenMessage {
  position: absolute;
  left: -9999px;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions {
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox {
  border: none;
  margin: 0;
  width: unset;
  padding: 8px 10px;
  text-align: center;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceText {
  height: 20px;
  border-radius: 25px;
  text-align: center;
  padding: 8px 20px;
  border: #333 2px solid;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: 600;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceText:before {
  font-family: 'Icon Fonts';
  content: "\e935";
  font-size: 10px;
  font-weight: normal;
  margin-right: 10px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput {
  margin: 0;
  height: 30px;
  top: 5px;
  left: 10px;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput:checked + .choiceText {
  border-color: #002e6d;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput:checked + .choiceText:before {
  font-family: 'Icon Fonts';
  content: "\e935";
  font-size: 10px;
  font-weight: normal;
  margin-right: 10px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceSelectedDecoration {
  display: none;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox:hover {
  -webkit-animation-name: unset;
          animation-name: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected1 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected2 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected3 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected4 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected5 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected6 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected7 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected8 {
  -webkit-animation: unset;
          animation: unset;
}
.survey .surveyContainer .surveyRegistrationText {
  text-align: center;
}
.survey .surveyContainer .surveyRegistrationText h2 {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.0625rem;
  line-height: 1.875rem;
}
.survey .surveyContainer .surveyRegistrationText p {
  margin-bottom: 10px;
}
.surveyPrivacyContainer {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  padding: 70px 0 150px 0;
}
.surveyPrivacyContainer h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0 8px 20px 8px;
}
.surveyPrivacyContainer h3 a {
  display: inline-block;
}
.surveyPrivacyContainer h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.surveyPrivacyContainer h3 a:hover,
.surveyPrivacyContainer h3 a:focus {
  text-decoration: none;
}
.surveyPrivacyContainer p {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin: 0 15px;
  font-weight: bold;
}
