.confirmPasswordInput .confirmPasswordField {
  margin-bottom: 10px;
}
.confirmPasswordInput .confirmPasswordField .confirmPasswordFieldInput {
  margin-bottom: 5px;
}
.confirmPasswordInput .confirmPasswordField .confirmPasswordFieldInput:focus {
  outline: 2px solid #002e6d;
  border: 1px solid transparent;
}
.confirmPasswordInput .confirmPasswordField .confirmPasswordFieldInput:active {
  outline: none;
  border: 1px solid #002e6d;
  box-shadow: 0 0 6px 0 rgba(0, 0, 180, 0.25);
  padding: 9px;
}
.confirmPasswordInput .confirmPasswordField .passwordButtonContainer {
  text-align: right;
}
.confirmPasswordInput .confirmPasswordField .passwordButtonContainer .showPasswordButton {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #002e6d;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-weight: bold;
}
.confirmPasswordInput .confirmPasswordField .passwordButtonContainer .showPasswordButton:hover:enabled {
  text-decoration: underline;
}
.confirmPasswordInput .confirmPasswordField .passwordButtonContainer .showPasswordButton:focus {
  text-decoration: underline;
}
