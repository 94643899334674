/* async:start */
.modal {
  display: none;
  z-index: 1001;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 430px;
  max-width: calc(100% - 80px);
  max-height: calc(100% - 80px);
}
.modal.visible {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
}
.modal .modalHeader {
  background-color: #002e6d;
  color: white;
  padding: 10px;
  min-height: 30px;
}
.modal .modalHeader .modalClose {
  position: absolute;
  right: 0;
  top: 0;
}
.modal .modalHeader .modalClose .modalCloseButton {
  background: none;
  padding: 12px;
}
.modal .modalHeader .modalClose .modalCloseButton #modalCloseButtonText {
  display: none;
}
.modal .modalHeader .modalClose .modalCloseButton:after {
  font-family: 'Icon Fonts';
  content: "\e923";
  color: white;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.modal .modalHeader .modalClose .modalCloseButton:focus {
  outline-offset: -3px;
  outline-color: white;
}
.modal .modalHeader .modalHeaderText {
  margin: 20px 40px;
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  text-align: center;
}
.modal .modalHeader .modalHeaderText a {
  display: inline-block;
}
.modal .modalHeader .modalHeaderText a {
  color: #3273f8;
  text-decoration: underline;
}
.modal .modalHeader .modalHeaderText a:hover,
.modal .modalHeader .modalHeaderText a:focus {
  text-decoration: none;
}
.modal .modalContent {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px 20px 30px;
}
.modal .modalFooter {
  background-color: #f5f5f6;
  padding: 20px;
  box-shadow: 0 -8px 8px #dadade;
  z-index: 1;
}
.overlay {
  display: none;
  z-index: 1000;
  background-color: #000;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.5;
}
.overlay.visible {
  display: block;
}
/* async:end */
